import { postAdminLogin } from "@/apis/login";
import GoiInput from "@/containers/Partners/PartnersContainer/components/GoiInput";
import { Show } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { AxiosError } from "axios";
import { GoiLogo, HStack, MQ, VStack, colors, queryKeys, setCookie } from "goi_common";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const LoginContainer = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [isSpeaking, setIsSpeaking] = useState(false);

  const router = useRouter();
  const queryClient = useQueryClient();

  const isDisabled = useMemo(() => {
    return id.length < 4 || password.length < 4;
  }, [id, password]);

  const { mutate } = useMutation(postAdminLogin, {
    onSuccess: async (res) => {
      await queryClient.refetchQueries(queryKeys.adminUser);
      await setCookie("teamjang_token", res.data.token);
      const redirectTo = router.query.redirectTo as string;
      if (res.data.role === "SUPERUSER") {
        router.push(redirectTo || "/parlor/");
      } else if (res.data.role === "TEAMJANG") {
        router.push(redirectTo || "/funeral/list/");
      } else if (res.data.role === "TEAMJANG_LEAD") {
        router.push(redirectTo || "/funeral/list/");
      } else {
        router.push(redirectTo || "/");
      }
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 403) {
        setIsSpeaking(true);
      }
    },
  });

  const login = () => {
    const body = {
      username: id,
      password,
    };

    mutate(body);
  };

  return (
    <div
      css={css`
        background-image: url("/images/home/big_song.png");
        background-size: 210%;
        ${MQ.md} {
          background-size: 140%;
        }
        ${MQ.lg} {
          background-size: cover;
          background-image: url("/images/home/bigger_song.png");
        }
        background-position: top;
        background-repeat: no-repeat;
        height: 100vh;
        width: 100%;
      `}
    >
      <Show above="lg">
        <HStack
          css={css`
            position: absolute;
            width: 100%;
            padding-left: 60px;
            padding-top: 60px;
            padding-right: 60px;
          `}
          justifyContent="space-between"
        >
          <VStack gap={20}>
            <GoiLogo />
            <div className="subtitle1_b">관리자 프로그램</div>
          </VStack>
          <img src="/images/home/hello_goi.png" width="250px" alt="" />
        </HStack>
      </Show>
      <VStack
        css={css`
          padding: 240px 24px 0 24px;

          ${MQ.lg} {
            padding: 53vh 24px 0 24px;
          }
        `}
        alignItems="center"
        gap={16}
      >
        {isSpeaking ? (
          <div
            css={css`
              margin-bottom: 35px;
            `}
            onClick={() => {
              setIsSpeaking(false);
            }}
          >
            <img
              css={css`
                height: 60px;
              `}
              src="/images/home/song_speaking.png"
              alt=""
            />
          </div>
        ) : (
          <div
            css={css`
              height: 95px;
            `}
          />
        )}
        <GoiInput
          placeholder="어드민 아이디를 입력하세요"
          value={id}
          setValue={setId}
          onKeyUp={(e) => e.key === "Enter" && login()}
        />
        <GoiInput
          placeholder="어드민 비밀번호를 입력하세요"
          type="password"
          value={password}
          setValue={setPassword}
          onKeyUp={(e) => e.key === "Enter" && login()}
        />
        <button
          className="subtitle2_sb"
          css={css`
            margin-top: 8px;
            width: 100%;
            max-width: 320px;
            height: 62px;
            border-radius: 5px;
            background: ${colors.gray700};
            color: ${isDisabled ? colors.gray600 : "white"};
          `}
          disabled={isDisabled}
          onClick={login}
        >
          로그인
        </button>
      </VStack>
    </div>
  );
};

export default LoginContainer;
