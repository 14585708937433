import axios from "axios";

interface PostAdminLoginBody {
  username: string;
  password: string;
}

export function postAdminLogin(body: PostAdminLoginBody) {
  return axios.post("/api/signin/", {
    phone: body.username,
    digit: body.password,
  });
}
